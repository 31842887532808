@import 'variables.scss';
@import 'mixins.scss';

body {
	color: $white;
	background: $blue;
}

.login form {
	border: none;
	box-shadow: none;
	background: $blue;
}

.login label {
	color: $white;
	font-size: 12px;
}

#nav,
#backtoblog {
	display: none;
}

.login input[type='text'],
.login input[type='password'],
#login input[type='tel'] {
	background: $blue;
	border: none;
	border-bottom: 1px solid $white;
	color: $white;
	box-shadow: none;
	font-weight: normal;
	font-family: 'Courier', 'Courier New', sans-serif;

	&:focus {
		outline: none;
		box-shadow: none;
	}
}

input[type='checkbox'] {
	border: 1px solid $white;
	background: $blue;

	&:checked:before {
		color: $blue;
	}

	&:focus {
		border: 1px solid $white;
		box-shadow: none;
	}
}

#wp-submit,
#submit {
	background: $blue;
	color: $white;
	border: 1px solid $white;
	border-radius: 4px;
	text-shadow: none;
	font-size: 12px;
	box-shadow: none;
	margin-top: 20px;
	width: 100%;

	&:hover {
		color: $blue;
		background: $white;
	}
}

.wp-core-ui .button-group.button-large .button,
.wp-core-ui .button.button-large {
	height: 40px;
}

.login #login_error {
	border: none;
	background: $red;
	color: $white;

	a {
		color: $white;

		&:hover {
			color: $blue;
		}
	}
}

.login .message {
	border: none;
	background: $blue;
	color: $white;
	box-shadow: none;
	text-align: center;
}

a:focus {
	box-shadow: none;
}

.cc_banner-wrapper {
	display: none !important; //Hide cookies notice on backend
}

.itsec-backup-methods {
	border: none !important;
	display: none !important;

	ul {
		list-style: none !important;
		padding: 0;
		margin: 0 !important;
	}

	a {
		color: $white;
		text-decoration: none !important;
	}
}
