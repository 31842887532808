//colors 
$blue: #283D4F;
$white: #FFFFFF;
$gray: #2E2E2E;
$facebook: #3b5998 ;
$twitter: #1da1f2 ;
$instagram: #f56040;
$red: #D0011B;
$light: #EDEDED;

//font weights
$fw-normal: 400;
$fw-bold: 700;

//widths
$w-mobile: 340px;
$w-mobile-landscape: 650px;
$w-tablet: 768px;
$w-tabletmax: 767px;
$w-desktop: 1024px;
$w-wide: 1350px;








